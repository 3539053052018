import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Banner,
  Categories,
  Foryou,
  Navbar,
  Offers,
  Help,
  Bottom,
  Install,
  WhyShop,
  Footer,
  Login,
  Overlay,
  Modal,
  Navigation,
  SearchResults,
} from "../../Components";

import { actionsCreator } from "../../Redux/actions/actionsCreator";
import giftIcon from "../../Assets/Images/gifs/gift.gif";
import medkit from "../../Assets/Images/medkit.png";
import girlwithmeds from "../../Assets/Images/girlwithmeds.png";
import bluebg from "../../Assets/Images/bluebg.png";

import BgImg1 from "../../Assets/Images/BackGroundImg1.png";
import BgImg2 from "../../Assets/Images/BackGroundImg2.png";

import tablets from "../../Assets/Images/tablets.png";
import playVideo from "../../Assets/Images/play-video.png";

import testi1 from "../../Assets/Images/Testimonial Card.png";
import testi2 from "../../Assets/Images/Testimonial Card copy.png";

import Logo1 from "../../Assets/Images/logo/Logo.png";
import Logo2 from "../../Assets/Images/logo/Logo-2.png";
import Logo3 from "../../Assets/Images/logo/Logo-3.png";
import Logo4 from "../../Assets/Images/logo/Logo-4.png";
import Logo5 from "../../Assets/Images/logo/Logo-5.png";

import Service1 from '../../Assets/Images/Service Card.png'
import Service2 from '../../Assets/Images/Services Card.png'
import Service3 from '../../Assets/Images/Service Card-2.png'

import CategoryImg1 from '../../Assets/Images/CategoryImg1.png'
import CategoryImg2 from '../../Assets/Images/CategoryImg2.png'

import { FaPlayCircle } from "react-icons/fa";
import "./Home.scss";
import { welcomeModalHandler } from "../../Utils";
import Bannerv2 from "../../Components/Homepage/Bannerv2";
import Slideshow from "../../Components/Homepage/Bannerv2/Slideshow";
import Dashboard from "../dashboard";
import ProductsSlider from "../../Components/ProductCategory/Products";
import Container1 from "../../Components/Container/Container1";
import { CiSearch } from "react-icons/ci";
import Caraousel from "../../Components/Caraousel/Caraousel";
import { useHistory } from "react-router-dom";
import useDebounce from "../../Hooks/useDebounce";
import Carousel from "../../Components/Caraousel/Caraousel";
import Callback from '../../Components/Callback/Callback.js'


const mapStateToProps = ({ auth, categories = {} }) => ({
  auth,
  categories,
});

const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    auth,
    categories: { list: categoryList },
  } = useSelector(mapStateToProps);

  const { isLoggedIn } = auth;
  const [offers, setOffers] = useState(true);
  const [subcategory, setSubcategory] = useState([]);

  const subcategoryAdd = () => {
    let temp_sub_category = [];
    categoryList.map((item) => {
      item.sub_categories &&
        item.sub_categories.map((subitem) => {
          if (subitem.home_page) {
            temp_sub_category.push(subitem);
          }
        });
    });
    setSubcategory(temp_sub_category);
  };
  useEffect(() => {
    subcategoryAdd();
  }, [categoryList]);

  const fetchCartDetails = () => {
    dispatch(actionsCreator.FETCH_CART_DETAILS());
  };

  const fetchFavouriteProducts = () => {
    dispatch(actionsCreator.FETCH_FAVOURITE_PRODUCTS());
  };

  // const [modal, setModal] = useState(true);
  useEffect(() => {
    if (isLoggedIn) {
      fetchCartDetails();
      fetchFavouriteProducts();
    }

    // setActiveCategory(categoryList[0]);
  }, [isLoggedIn]);


  const [query, setQuery] = useState('')
  const debouncedSearch = useDebounce(query, 500);

  return (
    <div className="home">
      {
        true &&
        <div 
          className="w-full flex items-center justify-center relative"
        > 
          <img
            src={BgImg2}
            className="banner-img1"
            style={{
              aspectRation : '1',
              objectFit : 'contain',
              position : 'absolute',
            }}
          />
          <img
            src={BgImg1}
            className="banner-img2"
            style={{
              aspectRation : '1',
              objectFit : 'contain',
              position : 'absolute',
            }}
          />
          <div
            className="flex flex-col gap-20 text-center banner-container-first"
          >
            <span className="text-black text-3xl bold-lg">
              Weaving digital experiences with <br/>
              <span style={{fontSize : '2.5rem'}} className="text-4xl">pixel perfection</span>
            </span>
            <span className="text-xl text-inactive">
              Transforming ideas into visually stunning designs while crafting user-friendly websites with a touch of innovation.
            </span>
            <div className="flex flex-row justify-center">
              <button
                style={{
                  backgroundColor : '#FF85D2'
                }}
                className="btn-sm round-40 text-white text-md"
                onClick={() => {
                  // history.push('/#contactus')
                  document.getElementById('contactus').scrollIntoView({behavior : 'smooth'})
                }}
              >
                Get in touch!
              </button>
            </div>
          </div>
        </div>
      }

      <div
        className="w-full nowrap text-center scrollbar-hidden"
        style={{
          backgroundColor : 'black',
          height : '5rem', 
          padding : '1rem',
          overflowX : 'scroll',
          overflowY : 'hidden',
          zIndex : '500'
        }}
      >
        <img
          src={Logo1}
          style={{
            height : '100%',
            objectFit : 'contain',
            marginRight : '2%'
          }}
        />
        <img
          src={Logo2}
          style={{
            height : '100%',
            objectFit : 'contain',
            marginRight : '2%'
          }}
        />
        <img
          src={Logo3}
          style={{
            height : '100%',
            objectFit : 'contain',
            marginRight : '2%'
          }}
        />
        <img
          src={Logo4}
          style={{
            height : '100%',
            objectFit : 'contain',
            marginRight : '2%'
          }}
        />
        <img
          src={Logo5}
          style={{
            height : '100%',
            objectFit : 'contain',
            marginRight : '2%'
          }}
        />
      </div>

      <div
        className="banner-container-2"
      > 
        <div
          className="flex responsive-flex-row justify-between items-center banner-container-2-1 gap-20"
        >
          <div className="text-3xl text-white bold-lg">
            Build Your Digital <br/> Product
          </div>
          <div>
            <button
              className="btn-md round-5 text-white text-lg"
              style={{
                backgroundColor : 'red',
              }}
              onClick={() => {
                document.getElementById('contactus').scrollIntoView({behavior : 'smooth'})
              }}
            >
              Contact Us
            </button>
          </div>
        </div>

        <div
          id="services"
          className="flex responsive-flex-row w-full justify-center gap-20 p-4"
        >
          <img
            src={Service1}
            className="round-20"
            style={{
              height : '20rem',
              aspectRatio : '1',
              objectFit : 'cover'
            }}
          />
          <img
            src={Service2}
            className="round-20"
            style={{
              height : '20rem',
              aspectRatio : '1',
              objectFit : 'cover'
            }}
          />
          <img
            src={Service3}
            className="round-20"
            style={{
              height : '20rem',
              aspectRatio : '1',
              objectFit : 'cover'
            }}
          />
        </div>
      <div>
            
        </div>
      </div>
              
      <div
        className="w-full flex flex-col banner-container-3"
        style={{
          // height : '60vh',
          backgroundColor : 'black',
          gap : '2rem'
        }}
      >
        <div
          className="text-white text-3xl bold-xl flex flex-col gap-20"
        >
          <div className="text-heading-1">
            From Idea to <br/> digital Solution
          </div>
          <div className="text-inactive text-md bold-md">
            We define, build, and scale digital products that people want and businesses need.
          </div>
        </div>

        <div className="w-full flex justify-end">
          <div 
            className="flex flex-col text-mark-1"
          >
            <mark
              className="text-white text-xl"
              style={{
                backgroundColor : 'red',
                padding : '10px',
                width : 'fit-content',
                alignSelf : 'flex-end'
              }}
            >
              Discover and Define
            </mark>
            <mark
              className="text-white text-md"
              style={{
                backgroundColor : 'red',
                padding : '10px',
              }}
            >
              Together we dive right into the problem with goal of uncovering ideas and opportunities. No vision is too big for a simple prototype.
            </mark>
          </div>
        </div>
      </div>

      {/* { isLoggedIn &&  <Banner />} */}

      {/* <Offers/> */}

      {
        [1,2].length > 0 &&
        <div className="p-4">
          <Carousel>
            {
              [1,2].map((item, ind) => {
                  return (
                    <button
                      className="flex-col gap-10"
                      style={{
                        marginRight : '1%'
                      }}
                    >
                      <img
                        src={ind == 0 ? CategoryImg1 : CategoryImg2}
                        className="round-10 card-hover"
                        style={{
                          height : '30rem',
                          aspectRatio : '1',
                          objectFit : 'contain',
                          display : 'inline',
                        }}
                      />
                      <span className="text-center text-md bold-lg">
                        {
                          item.name
                        }
                      </span>
                    </button>
                  )
                }
              )
            }
          </Carousel>
        </div>
      }

      <div
        className="w-full flex flex-col banner-container-3"
        style={{
          // height : '60vh',
          backgroundColor : '#1B1B1B',
          gap : '2rem'
        }}
      >
        <div
          className="text-white text-md bold-xl flex flex-col gap-10"
        >
          <div
            style={{
              color : '#B285FF',
            }}
          >
            Testimonials
          </div>
          <div className="text-white text-2xl bold-md">
            What client's say
          </div>
        </div>

        <div>
          <Caraousel>
            <div
              className="flex"
              style={{
                width : '30rem',
                height : '16rem',
                display : 'inline-block',
              }}
            > 
              <img
                src={testi2}
                style={{
                  height : '100%',
                  // width : '100%',
                }}
              />
            </div>
            <div
              className="flex"
              style={{
                width : '30rem',
                height : '16rem',
                display : 'inline-block',
                overflow : 'hidden'
              }}
            > 
              <img
                src={testi1}
                style={{
                  height : '100%',
                  width : '100%',
                  objectFit : 'contain'
                }}
              />
            </div>
          </Caraousel>
        </div>
      </div>

      <div
        className="w-full flex responsive-flex-row banner-container-3"
        style={{
          // height : '60vh',
          backgroundColor : '#FFFFFF',
          gap : '2rem'
        }}
      >
        <div
          className="text-white text-md bold-xl flex flex-col gap-10 flex-1"
        >
          <div
            style={{
              color : '#B285FF',
            }}
          >
            Contact
          </div>
          <div className="text-black text-2xl bold-md">
            Ready to start your project?
          </div>
          <div className="text-inactive">
            Dive into a world where technology and artistry converge, elevating online interactions and setting new standards for digital excellence.
          </div>
        </div>

        <div id="contactus" className="flex-1">
          <Callback/>
        </div>
      </div>

    </div>
  );
};

export default Home;
