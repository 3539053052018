// import { Button } from "bootstrap";
import InputField from "../InputField"
import { useSelector } from "react-redux";
import { useState } from "react";
import './Callback.scss'
import { callBackRequest } from "../../Api/contactApi";
import { toast } from "react-toastify";

const mapStateToProps = ({ cart, auth, payment }) => ({
    auth,
  });

const Callback = ({toggleContactFormModal})=>{
    const {  auth } = useSelector(mapStateToProps);
  const { isLoggedIn, user = {} } = auth;
  const [phone_number, setPhoneNumber] = useState("")
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const [formData, setFormData] = useState({
    name : '',
    surname : '',
    email : '',
    industry : '',
    message : '',
    tenant : auth.tenantDetails?.id,
    phone_number : '320',
    issue : 'callback request',
    description : 'callback request',
    ticket_type : 'callback req',
  })

  const changeHandler = (e)=>{
    const {name, value} = e.target
    setFormData((prev)=>{
        return {
            ...prev,
            [name]: value
        }
    })
  } 
  const submitHandler = async (data)=>{
    try{
        // let data = {}
        
            // if(isLoggedIn){
            //     data["email"] = email
            //     data["phone_number"] = phone_number
            //     data["name"] = name

            // }
            // else{
            //     data["email"] = email
            // }
               
        const response = await callBackRequest(formData)
        toast.success("We will get back to you soon")
        toggleContactFormModal()

    }catch(err){
        // handle error case
        
    }
    
  }
    return(
        <div className="flex flex-col gap-10">
            <InputField
                type='text'
                placeholder='Name'
                name="name"
                onChange={changeHandler}
                value={formData.name}
                label={'Enter your Name'}
                required={true}
                // value={phone_number}
                // onChange={(e) => onChange(e, 'PHONE')}
                // error={findError('phone_number', errors, showErr)}
            />

            <InputField
              type='text'
              placeholder='Surname'
              name="surname"
              onChange={changeHandler}
              value={formData.surname}
              label={'Enter your Surname'}
              required={true}
            
              // value={phone_number}
              // onChange={(e) => onChange(e, 'PHONE')}
              // error={findError('phone_number', errors, showErr)}
            />

            <InputField
              type='email'
              placeholder='Email'
              label={'Enter your email'}
              name="email"
              onChange={changeHandler}
              value={formData.email}
              required={true}
              // onChange={(e) => onChange(e, 'PHONE')}
              // error={findError('phone_number', errors, showErr)}
            />

            <InputField
            type='text'
            placeholder='Industry'
            label={'Enter your industry'}
            name="industry"
            onChange={changeHandler}
            value={formData.industry}
            required={true}
            // onChange={(e) => onChange(e, 'PHONE')}
            // error={findError('phone_number', errors, showErr)}
            />

            <InputField
              type='email'
              placeholder='Message'
              label={'Your Message'}
              name="message"
              onChange={changeHandler}
              value={formData.message}
              required={true}
              textarea={true}
              // onChange={(e) => onChange(e, 'PHONE')}
              // error={findError('phone_number', errors, showErr)}
            />

          <div className="flex flex-row gap-10">
              <button
                className="btn-none btn-sm round-40 text-white text-md"
                onClick={submitHandler}
                style={{
                  backgroundColor : 'black'
                }}
              >
                Contact
              </button>
          </div>
            
        </div>
    )
}

export default Callback