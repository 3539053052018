import React from 'react'

const Container1 = ({
    children, 
    header1, 
    header2,
    className,
    style, 
}) => {
  return (
    <div className={className} style={style}>
        <div className='flex flex-col gap-10'>
            {
                header1 &&
                <p className={`${header1 == "Product" && 'block-lg-only'} text-2xl semi-bold text-first`}>
                    {
                        header1
                    }
                </p>
            }
            {
                header2 &&
                <p className='text-large medium-bold text-first'>
                    {
                        header2
                    }
                </p>
            }
        </div>
        {
            children
        }
    </div>
  )
}

export default Container1