import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";

const Carousel = ({ 
    children, 
    className, 
    style,
    autoScroll,
 }) => {
  const caraRef = useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);

  const handleScroll = () => {
    const { scrollLeft, scrollWidth, clientWidth } = caraRef.current;

    // Check if there is some scrolling towards the right
    setShowLeftButton(scrollLeft > 0);

    // You can also check if scrolling towards the right end to hide right button if needed
    // const showRightButton = scrollLeft < scrollWidth - clientWidth;
  };

  const handleLeftClick = () => {
    console.log('leftright')
    caraRef.current.scrollLeft -= 250; // Adjust the scrolling amount as needed
  };

  const handleRightClick = () => {
    console.log('leftright')
    caraRef.current.scrollLeft += 250; // Adjust the scrolling amount as needed
  };

//   useEffect(() => {
//     // Auto-scroll every 3 seconds
//     if(autoScroll){
//     const intervalId = setInterval(() => {
//         const { scrollLeft, scrollWidth, clientWidth } = caraRef.current;
//         const maxScroll = scrollWidth - clientWidth;
    
//         if (scrollLeft < maxScroll) {
//             caraRef.current.scrollLeft += 250; // Scroll to the right
//         } else {
//             caraRef.current.scrollLeft = 0; // Reset to the beginning
//         }
//         }, 4000);
    
//         // Clean up the interval on component unmount
//         return () => clearInterval(intervalId);
//     }
//   }, []); // Empty dependency array ensures the effect runs only once

  return (
    <div
      className={`${className} relative w-full scrollbar-hidden`}
      style={{
        overflowX: "hidden",
        overflowY: "hidden",
        whiteSpace: "nowrap",
        zIndex : '100',
        ...style,
      }}
    >
      <button
        className="absolute"
        style={{
          left: "1.3rem",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: "1000",
          visibility: showLeftButton ? "visible" : "hidden",
        }}
        onClick={handleLeftClick}
      >
        <FaChevronCircleLeft size={"2rem"} color={"#30363C"} />
      </button>
      <button
        className="absolute"
        style={{
          right: "1.3rem",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: "10000",
        }}
        onClick={handleRightClick}
      >
        <FaChevronCircleRight size={"2rem"} color={"#30363C"} />
      </button>
      <div
        ref={caraRef}
        className={`carousel relative nowrap scrollbar-hidden`}
        style={{
          overflowX: "scroll",
          overflowY: "hidden",
          whiteSpace: "nowrap",
          paddingBottom: "1rem",
          scrollBehavior: "smooth",
        }}
        onScroll={handleScroll}
      >
        {children}
      </div>
    </div>
  );
};

export default Carousel;
